import React from 'react';
import logo from "../assets/images/final-logo.png"
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import Container from './Container';

const Footer = () => {
    return (
        <Container>
            <footer className="bg-white pb-8 pt-12 rounded-xl border-2 border-gray-200 shadow-sm max-md:mb-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-center items-center text-center w-full">
                        <div>
                            <div className='flex justify-center'>
                                <Link to="/">
                                    <img src={logo} alt="boltwireless" className='w-[130px]' />
                                </Link>

                            </div>
                            <p className='mt-4 font-light px-[15%]'>Welcome to Alkhairi Fast Food Delivery, your go-to spot for delicious, quick, and convenient meals. Enjoy our wide variety of freshly prepared dishes, delivered straight to your doorstep. Quality food, exceptional service, every time!</p>
                            <ul className='flex gap-6 my-4 justify-center'>
                                <li><Link to="https://www.facebook.com/profile.php?id=61561993988608" target='blank'><FaFacebook className='text-[24px] text-themeColor' /></Link></li>
                                <li><Link to="https://www.instagram.com/foodalkhairi/" target='blank'><FaInstagram className='text-[24px] text-themeColor' /></Link></li>
                                <li><Link to="https://www.tiktok.com/@alkhairifood?lang=en" target='blank'><FaTiktok className='text-[24px] text-themeColor' /></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <div className='text-center'>
                            <p>If you have any questions, feel free to contact us at:</p>
                            <p><a className='text-[#A10E1D] font-medium' href="mailto:durranimuhammadfarooq16@gmail.com">durranimuhammadfarooq16@gmail.com</a> | Phone: <a className='text-[#A10E1D] font-medium' href="tel:+923119635600">+92 311 9635600</a></p>
                        </div>
                    </div>
                    <hr className="my-6 border-themeColor sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400 text-center">Copyright © {new Date().getFullYear()} <Link to="/" className="hover:underline text-[#A10E1D] font-medium"> alkhairifood.com </Link>| Powered by <Link target='_blank' to='https://logicracks.co.uk' className='font-medium hover:underline text-[#A10E1D]'>Logicracks</Link></span>
                </div>
            </footer>
        </Container>
    );
};

export default Footer;
