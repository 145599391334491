import React, { memo, useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd'; // Import Skeleton from Ant Design
import catLeft from "../assets/images/cat-left-icon.png";
import catRight from "../assets/images/cat-right-icon.png";

const CatProducts = ({ cat }) => {
    const { allProducts } = useSelector(state => state.product);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        if (allProducts) {
            setFilteredProducts(
                allProducts.filter(product => product.cid === cat.c_id)
            );
        }
    }, [allProducts, cat]);

    return (
        <div className='mb-6 sm:mb-12'>
            <div className='py-2 px-6 bg-primary text-white flex justify-between items-center rounded-lg shadow-md'>
                <img src={catLeft} className='w-[60px] md:w-[90px]' alt="cat" />
                <h3 className='text-[20px] text-center md:text-[35px] font-medium font-sans leading-none'>{cat.c_name}</h3>
                <img src={catRight} className='w-[60px] md:w-[90px]' alt="cat" />
            </div>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 my-4 pb-4 border-b border-gray-300">
                {filteredProducts.length === 0 ? (
                    // Show Skeleton Loader when there are no filtered products
                    Array.from({ length: 4 }).map((_, index) => (
                        <div key={index} className='h-full'>
                            <Skeleton active paragraph={{ rows: 4 }} title={{ width: '100%' }} className='h-[400px]' />
                        </div>
                    ))
                ) : (
                    filteredProducts.map((product) => (
                        <ProductCard key={product.f_id} product={product} />
                    ))
                )}
            </div>
        </div>
    );
}

export default memo(CatProducts);
