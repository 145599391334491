import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Carousel, Input } from 'antd';
import CatProducts from '../components/CatProducts';
import Container from '../components/Container';
import Herosection from '../components/Herosection';
import { Link } from 'react-scroll';
import FAQ from '../components/FAQ';
import LocationModal from '../components/LocationModal';
import { SearchOutlined } from '@ant-design/icons';
import ProductCard from '../components/ProductCard';

const Home = () => {

    const categories = useSelector(state => state.product.allCategories) || [];
    const { allProducts } = useSelector(state => state.product);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchInput, setSearchInput] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        if (searchInput) {
            const filtered = allProducts.filter(prod =>
                prod.f_name.toLowerCase().includes(searchInput.toLowerCase())
            );
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts([]);
        }
    }, [searchInput, allProducts]);

    const handleCategorySelect = (category) => {
        setSelectedCategory(selectedCategory === category ? null : category);
    };

    const renderCarouselItems = () => {
        const itemsPerSlide = 3; // Adjust this number based on your design needs
        const totalSlides = Math.ceil(categories.length / itemsPerSlide);
        const slides = [];

        for (let i = 0; i < totalSlides; i++) {
            const start = i * itemsPerSlide;
            const end = start + itemsPerSlide;
            const categoriesInSlide = categories.slice(start, end);

            // Pad with null elements to ensure each slide has exactly `itemsPerSlide` items
            while (categoriesInSlide.length < itemsPerSlide) {
                categoriesInSlide.push(null);
            }

            slides.push(
                <div key={i} className="flex justify-center">
                    {categoriesInSlide.map((category, index) => (
                        category ? (
                            <Link to={`${category.c_id}`}
                                spy={true}
                                smooth={true}
                                offset={-200}
                                duration={800}
                                key={category.c_id}
                                className={`cursor-pointer rounded-md px-4 lg:px-6 py-1 mr-1 lg:mr-2 my-3 ${selectedCategory === category.c_id ? "bg-primary text-white" : "bg-[#E5E5E5] text-primary"}`}
                                size="large"
                                onClick={() => handleCategorySelect(category.c_id)}
                            >
                                <div className='flex flex-col items-center justify-center text-center'>
                                    <img
                                        src={category.icon}
                                        alt="icon"
                                        className={`w-[30px] lg:w-[40px] ${selectedCategory === category.c_id ? "filter brightness-0 invert" : ""}`}
                                    />
                                    <p>{category.c_name}</p>
                                </div>
                            </Link>
                        ) : (
                            <div key={index} className="flex items-center justify-center" style={{ width: 120 }}>
                                {/* Placeholder for empty space in the carousel */}
                            </div>
                        )
                    ))}
                </div>
            );
        }

        return slides;
    };

    return (
        <div>
            <Herosection />
            <Container>
                {/* product categories  */}

                <div className='productWithCategory'>
                    <div className="sticky top-[94px]  md:top-[108px] z-[999] border-t border-gray-400">
                        <div className="p-0 bg-white rounded-b-lg shadow-sm">
                            <div className="hidden md:flex md:flex-wrap justify-center">
                                {categories.map((category) => (
                                    <Link to={`${category.c_id}`}
                                        spy={true}
                                        smooth={true}
                                        offset={-210}
                                        duration={800}
                                        key={category.c_id}
                                        className={`cursor-pointer rounded-md px-5 lg:px-6 py-1 mr-1 lg:mr-2 my-3 ${selectedCategory === category.c_id ? "bg-primary text-white" : "bg-[#E5E5E5] text-primary"}`}
                                        size="large"
                                        onClick={() => handleCategorySelect(category.c_id)}
                                    >
                                        <div className='flex flex-col items-center justify-center text-center'>
                                            <img
                                                src={category.icon}
                                                alt="icon"
                                                className={`w-[30px] lg:w-[40px] ${selectedCategory === category.c_id ? "filter brightness-0 invert" : ""}`}
                                            />
                                            <p className='text-[14px] lg:text-[16px]'>{category.c_name}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            <div className="md:hidden mobile-menu">
                                {/* Render as carousel items in mobile view */}
                                <Carousel arrows autoplay={false} dots={false} draggable={true}>
                                    {renderCarouselItems()}
                                </Carousel>
                            </div>
                        </div>
                    </div>

                    <div class="w-full p-4">
                        <Input
                            class="w-full rounded-full px-4 py-2 shadow-md focus:outline-none"
                            placeholder="Search for products..."
                            suffix={<SearchOutlined />}
                            onChange={(e) => setSearchInput(e.target.value)}
                            size="large"
                        />
                    </div>
                    {/* Category Products */}
                    <div>
                        {filteredProducts.length > 0 &&

                            <div>
                                <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 my-4 pb-4 border-b border-gray-300">
                                    {filteredProducts.length && filteredProducts.map((product) => (
                                        <ProductCard key={product.f_id} product={product} />
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='my-8'>
                        {categories.map((cat) => (
                            <div id={cat.c_id} key={cat.c_id}>
                                <CatProducts cat={cat} />
                            </div>
                        ))}
                    </div>
                </div>

                <FAQ />
            </Container>
            <LocationModal />
        </div>
    );
};

export default Home;